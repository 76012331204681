<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Crear Atributos' }]"
    />
    <v-card>
      <v-toolbar card color="primary" dark>
        <v-toolbar-title>Crear atributo</v-toolbar-title>
      </v-toolbar>
      <!-- <v-bottom-nav :active.sync="activeBtn" color="transparent" :value="true">
        <v-btn :to="{ name: 'EditProduct', params: { id: $route.params.id } }" color="grey darken-1" flat>
          <span>Informacion</span>
          <v-icon>assignment</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaProductAtributo', params: { id: $route.params.id } }" color="teal" flat>
          <span>Atributos</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaProductImagen', params: { id: $route.params.id } }" color="grey darken-1" flat>
          <span>Imagenes</span>
          <v-icon>image</v-icon>
        </v-btn>
        <v-btn :to="{ name: '', params: { id: $route.params.id } }" color="grey darken-1"  flat>
          <span>Etiquetas</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListRating', params: { id: $route.params.id } }" color="grey darken-1" flat>
          <span>Ratings</span>
          <v-icon>star</v-icon>
        </v-btn>
      </v-bottom-nav> -->
      <!-- <br />
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Crear Atributos</v-toolbar-title>
        <v-spacer />
      </v-toolbar> -->
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Generar numero de atributos</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">Atributos Generados</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-5" height="100px">
              <v-flex sm3 xs12>
                <v-text-field
                  v-model="numeroAtributos"
                  label="Ingrese la cantidad de atributos"
                  outline
                />
              </v-flex>
            </v-card>
            <v-autocomplete
              v-model="producto_id"
              :items="productos"
              dense
              outline
              clearable
              small-chips
              label="Seleccionar Producto"
              item-text="nombre"
              item-value="id"
            />
            <v-btn color="primary" @click="add()">
              Generar
            </v-btn>
            <v-btn
              color="error"
              @click="
                $router.push({ name: 'ListaProductAtributo', params: { id: $route.params.id } })
              "
            >
              Volver
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-5">
              <v-layout row wrap>
                <v-flex xs12 v-if="generar === true">
                  <div v-for="(atributo, k) in form.atributos" :key="k">
                    <v-layout row wrap>
                      <v-flex sm3 xs12>
                        <v-autocomplete
                          v-model="atributo.clasificacion_id"
                          :items="clasificaciones"
                          dense
                          outline
                          clearable
                          small-chips
                          label="Clasificación"
                          item-text="nombre"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex sm2 xs12>
                        <v-text-field
                          v-model="atributo.nombre_campo"
                          label="Nombre campo"
                          outline
                        />
                      </v-flex>
                      <v-flex sm2 xs12>
                        <v-autocomplete
                          v-model="atributo.tipo_campo"
                          :items="tipos"
                          dense
                          outline
                          clearable
                          small-chips
                          label="Tipo Campo"
                          item-text="texto"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex sm2 xs12>
                        <v-text-field v-model="atributo.valor_campo" label="Valor Campo" outline />
                      </v-flex>
                      <v-flex sm2 xs12>
                        <v-autocomplete
                          v-model="atributo.mostrar"
                          :items="estados"
                          dense
                          outline
                          clearable
                          small-chips
                          label="Mostrar"
                          item-text="nombre"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex sm1 xs12>
                        <v-btn fab dark color="pink" @click="deleteAtribute(k)" small>
                          <v-icon dark>close</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
            <v-btn color="success" @click="submitAtributos()">
              Guardar
            </v-btn>

            <v-btn flat @click="deleteFormAtributos()">Volver</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { baseUrlAPI } from "@/config/api";
import axios from "axios";
const HOST = baseUrlAPI;

export default {
  metaInfo() {
    return { title: "Listado de comentarios" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },
  data() {
    return {
      activeBtn: 1,
      numeroAtributos: 0,
      numeros: 0,
      e1: 0,
      producto_id: "",
      generar: false,
      productos: [],
      form: {
        atributos: [
          {
            nombre_campo: "",
            valor_campo: "",
            mostrar: 1,
            tipo_campo: "",
            clasificacion_id: ""
          }
        ]
      },
      tipos: [
        { id: "INT", texto: "Entero" },
        { id: "DOUBLE", texto: "Decimal" },
        { id: "TEXT", texto: "Texto" },
        { id: "CURRENCY", texto: "Moneda" }
      ],
      estados: [
        { id: 1, nombre: "Si" },
        { id: 0, nombre: "No" }
      ]
    };
  },

  computed: {
    ...mapState({
      clasificaciones: state => state.clasificaciones.clasificaciones
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getProducts();
    this.getClasificaciones();
  },

  methods: {
    ...mapActions({
      getProductos: "productos/getProductos",
      postAtributos: "productos/postAtributos",
      getAtributos: "productos/getAtributos",
      getClasificaciones: "clasificaciones/getClasificaciones"
    }),
    async getProducts() {
      try {
        const res = await axios.get(`${HOST}/productos/general`);
        this.productos = res.data.data;
      } catch (error) {
        this.$message.error("error al traer productos");
      }
    },
    add() {
      this.e1 = 2;
      this.generar = true;
      if (this.numeroAtributos != 0) {
        this.form.atributos = [];
        for (let paso = 0; paso < this.numeroAtributos; paso++) {
          this.setForm();
        }
      } else {
        this.getAtributos({ productoId: this.producto_id }).then(response => {
          const atributosInfo = response.data.data;
          this.form.atributos = atributosInfo;
        });
      }
    },
    setForm() {
      this.form.atributos.push({
        nombre_campo: "",
        valor_campo: "",
        mostrar: 1,
        tipo_campo: ""
      });
    },
    deleteFormAtributos() {
      var paso;
      for (paso = 0; paso < this.numeroAtributos - 1; paso++) {
        this.deleteForm();
      }
      this.e1 = 1;
    },
    deleteAtribute(index) {
      this.form.atributos.splice(index, 1);
    },
    deleteForm() {
      this.form.atributos.pop({
        nombre_campo: "",
        valor_campo: "",
        mostrar: 1,
        tipo_campo: ""
      });
    },
    submitAtributos() {
      this.postAtributos({ productoId: this.$route.params.id, data: this.form })
        .then(response => {
          this.processingForm = false;
          this.$router.push({
            name: "ListaProductAtributo",
            params: { id: this.$route.params.id }
          });
        })
        .catch(error => {
          console.log(error);
          //this.processingForm = false;
        });
    }
  }
};
</script>
